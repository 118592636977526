// import { useSentry } from '@/plugins/sentry'
import Cookies from 'js-cookie'
import DistributionTypes from '@/config/DistributionTypes'
import { useI18n } from '@/i18n'
import { segment } from '@/plugins/globals/segment/index.js'

/**
 * @returns {{locale: *}}
 */
export function getContext() {
  const i18n = useI18n()

  return {
    locale: i18n.locale,
  }
}

/**
 * @param productsArr
 * @param establishment
 * @param minimal
 *
 * @returns {{quantity: *, price, name, variant, category: *, brand, brand_type: (string)}[]}
 */
export function formatProducts(
  productsArr = [],
  establishment,
  minimal = false
) {
  return productsArr.map((item, index) => {
    // turn cart-items into normal item
    if (item.product) {
      item = {
        ...item.product,
        img: item.img,
        amount: item.amount,
      }
    }

    let product = {
      category: item.product_set,
      name: item.title,
      brand: establishment.title,
      brand_type: 'restaurant',
      variant: item.description,
      price: item.price,
      quantity: item.amount,
      img: item.img,
    }

    if (!minimal) {
      product = {
        ...product,
        product_id: item.id,
        sku: item.id,
        brand_id: establishment.id,
        position: item.position || index + 1,
      }
    }

    return product
  })
}

/**
 * @param distributionType
 *
 * @returns {string}
 */
export function formatDistSlug(distributionType) {
  switch (distributionType) {
    case DistributionTypes.Delivery:
      return 'delivery'
    case DistributionTypes.TakeAway:
      return 'take-away'
    default:
      return 'on-location'
  }
}

export async function getAnonymousId() {
  const user = await segment.user()

  return user.anonymousId()
}

/**
 *
 * @returns {*}
 */
export function getGAClientId() {
  const ga = Cookies.get('_ga')

  try {
    // clientId = randomHash + creationTime
    const [_tagVersion, _domainLevel, randomHash, creationTime] = ga.split('.')

    return `${randomHash}.${creationTime}`
  } catch (e) {
    return null
  }
}

/**
 * Based on Stack Overflow answer: https://stackoverflow.com/questions/72784667/sending-session-id-via-measurement-protocol#answer-73253670
 *
 * @param cookie
 *
 * @returns {String}
 */
export function getGASessionId(cookie = import.meta.env.VITE_GA4_COOKIE_NAME) {
  if (!cookie) return undefined

  const ga = Cookies.get(cookie)

  return ga?.split('.')[2]
}

/**
 * @param products
 *
 * @returns {*[]|*}
 */
export function limitCartLength(products) {
  if (!JSON.stringify(products).length > 950) return products
  let newProducts = []

  products.forEach((product) => {
    if (JSON.stringify(newProducts).length < 950) {
      newProducts.push(product)
    }
  })

  return newProducts
}
