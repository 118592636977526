import {MODAL_TOGGLE} from '@/plugins/globals/segment/handlers'

const TOGGLE = 'TOGGLE'
const SET_DATA = 'SET_DATA'

export const CARTCHANGED_MODAL = 'CARTCHANGED_MODAL'
export const ERROR_MODAL = 'ERROR_MODAL'
export const NOT_HERE_YET_MODAL = 'NOT_HERE_YET_MODAL'
export const LOGIN_MODAL = 'LOGIN_MODAL'
export const REORDER_MODAL = 'REORDER_MODAL'

const getDefaults = () => ({
  open: false,
  data: {},
})

export const getDefaultState = () => ({
  [CARTCHANGED_MODAL]: getDefaults(),
  [ERROR_MODAL]: getDefaults(),
  [NOT_HERE_YET_MODAL]: getDefaults(),
  [LOGIN_MODAL]: getDefaults(),
  [REORDER_MODAL]: getDefaults(),
})

export const state = () => ({
  ...getDefaultState(),
})

const exists = (modal) => {
  if (!Object.keys(getDefaultState()).includes(modal))
    throw new Error(`Modal with name "${modal}" not in modals/state.`)
}

export const getters = {
  open: (state) => (modal) => {
    return state[modal].open
  },

  data: (state) => (modal) => {
    return state[modal].data
  },
}

export const actions = {
  async toggle ({ commit, dispatch }, { modal, open, data }) {
    exists(modal)

    // commit optional data before opening the modal
    await dispatch('setData', { modal, data })

    // commit visibility
    commit(TOGGLE, { modal, open })

    // this.$segment.handle(MODAL_TOGGLE, {
    //   modal,
    //   open
    // })
  },

  setData: ({ commit }, { modal, data }) => {
    exists(modal)

    // commit optional data before opening the modal
    if (data) commit(SET_DATA, { modal, data })
  },
}

export const mutations = {
  [TOGGLE]: (state, { modal, open }) => {
    state[modal] = {
      ...state[modal],
      open: open,
    }
  },

  [SET_DATA]: (state, { modal, data }) => {
    state[modal] = {
      ...state[modal],
      data,
    }
  },
}
