import type { Coordinates, DistributionType } from '@/http/models/Establishment'
import type { Location } from '@/types/Location'
import { useStore } from '@/stores'
import { useHttp } from "@/composables/useHttp"
import type { SearchedEstablishment } from "@/types/SearchedEstablishment"

type Paginated<T> = {
  data: T[]
  links: {
    first: string
    last: string
    prev: string | null
    next: string | null
  }
  meta: {
    current_page: number
    from: number
    last_page: number
    links: {
      active: boolean
      label: string
      url: string | null
    }[]
    path: string
    per_page: number
    to: number
    total: number
  }
}

export type EstablishmentsFilter = {
  distribution: DistributionType
  location?: Coordinates
  q: string | null
  sort: 'distance'
}

export function useEstablishmentHttp() {
  const http = useHttp()

  async function establishmentsByCityAndCountry(city: string, country: string, { categories }: { categories: string[] }) {
    const response = await http.get(`countries/${country}/cities/${city}/establishments/seo`, {
      params: {
        categories,
      }
    })

    return response.data.data
  }

  async function establishments(filters: EstablishmentsFilter): Promise<Paginated<object>> {
    return (await http.get('establishments', {
      params: {
        ...filters
      },
    })).data
  }

  async function searchEstablishments(q: string, distribution: number, location: {
    lat: number | undefined,
    lng: number | undefined
  }): Promise<{
    count: number,
    establishments: Array<SearchedEstablishment>
  }> {
    const response = await http.get('establishments/search', {
      params: {
        q: q,
        distribution: distribution,
        'location[lat]': location.lat,
        'location[lng]': location.lng,
      },
    })

    return response.data.data
  }

  async function establishment(slug: string, country: string, city: string, hash: string | null = null) {
    let params = {}

    if (hash !== null) {
      params.hash = hash;
    }

    return (await http.get(`countries/${country}/cities/${city}/establishments/${slug}`, {
      params: params
    })).data.data
  }

  async function establishmentByDistributionType(slug: string, country: string, city: string, distributionType: DistributionType, location: Location | null = null) {
    let params = {}

    // only delivery requires a location
    if (location !== null) {
      params.lat = location.coordinates.lat
      params.lng = location.coordinates.lng
    }

    return (await http.get(`/countries/${country}/cities/${city}/establishments/${slug}/distributions/${distributionType}`, {
      params: params
    })).data.data
  }

  async function fakePaymentMethods(slug: string, country: string, city: string) {
    const { data } = await http.get(`/countries/${country}/cities/${city}/establishments/${slug}/payments/integrations/fake/methods`)

    return data.data
  }

  async function molliePaymentMethods(establishment: string, country: string, city: string, amount: number) {
    const { data } = await http.get(`/countries/${country}/cities/${city}/establishments/${establishment}/payments/integrations/mollie/methods?amount=${amount}`)

    return data.data
  }

  async function priceRules(establishment: string, country: string, city: string): Promise<any[]> {
    const { data } = await http.get(`/countries/${country}/cities/${city}/establishments/${establishment}/price-rules`)

    return data.data
  }

  async function establishmentsByCategory(category: string) {
    const { data } = await http.get(`/categories/${category}/establishments`)

    return data.data
  }

  async function faq(establishment: string, country: string, city: string) {
    const { data } = await http.get(`/countries/${country}/cities/${city}/establishments/${establishment}/faqs`)

    return data.data
  }

  const store = useStore()

  async function reviews(establishment: string, country: string, city: string) {
    const { data } = await http.get(`/countries/${country}/cities/${city}/establishments/${establishment}/reviews`)

    await store.dispatch('establishment/storeReviews', data.data)
  }

  async function timeslots(establishment: string, country: string, city: string, params: any) {
    const { data } = await http.post(`/countries/${country}/cities/${city}/establishments/${establishment}/timeslots`, params)

    return data.data
  }

  return {
    fakePaymentMethods,
    molliePaymentMethods,
    establishment,
    establishmentByDistributionType,
    priceRules,
    faq,
    reviews,
    establishments,
    establishmentsByCityAndCountry,
    establishmentsByCategory,
    timeslots,
    searchEstablishments,
  }
}
