import { inject, type App } from 'vue'
import type { Router } from 'vue-router'
import { version } from '../../package.json'
import { ignoreErrors } from '@/utils/sentry/ignoreErrors'
import { denyUrls } from '@/utils/sentry/denyUrls'
import type * as SentryNode from '@sentry/node'
import type * as SentryVue from '@sentry/vue'

type SentryPluginOptions = {
  router: Router
  dsn: string
  environment?: string
}

export const sentryInjectionKey = Symbol()

type SentryNode = typeof SentryNode
type SentryVue = typeof SentryVue

export function useSentry() {
  return inject<SentryNode | SentryVue>(sentryInjectionKey)
}

export async function createSentry(opts: SentryPluginOptions) {
  let Sentry: SentryNode | SentryVue

  const { dsn, environment, router } = opts

  const config = {
    release: version,
    dsn,
    environment,
    enabled: import.meta.env.PROD,
  }

  if (import.meta.env.SSR) {
    // @ts-ignore
    globalThis._sentryEsmLoaderHookRegistered = true

    Sentry = await import('@sentry/node') as SentryNode
  } else {
    Sentry = await import('@sentry/vue') as SentryVue
  }

  return {
    install(app: App) {
      if (!import.meta.env.SSR) {
        (Sentry as SentryVue).init({
          app,
          ...config,
          integrations: [
            (Sentry as SentryVue).replayIntegration({
              maskAllText: false,
              maskAllInputs: false,
              blockAllMedia: false,
              networkDetailAllowUrls: [
                window.location.origin,
                'bistroo.nl',
                'staging.bistroo.nl',
              ],
            }),
            (Sentry as SentryVue).browserTracingIntegration({
              router,
            }),
          ],
          tracesSampleRate: 0,
          replaysSessionSampleRate: 0,
          replaysOnErrorSampleRate: 1.0,
          ignoreErrors,
          denyUrls,
        });

        (Sentry as SentryVue).attachErrorHandler(app, {
          logErrors: import.meta.env.DEV,
          attachProps: true,
          trackComponents: false,
          timeout: 0,
          hooks: [],
        })
      }

      app.provide(sentryInjectionKey, Sentry)
    },
    instance: Sentry,
  }
}
