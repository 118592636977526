import { Capacitor } from '@capacitor/core'
import { useI18n } from '@/i18n'
import { getContext } from '@/plugins/globals/segment/lib/helpers'

export default class SegmentApi {
  /**
   * @param analyticsBrowser
   * @param store
   */
  constructor(analyticsBrowser, store) {
    this.analyticsBrowser = analyticsBrowser
    this.store = store
  }

  /**
   * @param name
   * @param properties
   *
   * @returns {*}
   */
  page(name, properties = {}) {
    if (Capacitor.isNativePlatform()) {
      return this.analyticsBrowser.screen(name, properties, {
        context: getContext(),
      })
    }

    return this.analyticsBrowser.page(name, properties, {
      context: getContext(),
    })
  }

  /**
   * @param userId
   * @param properties
   *
   * @returns {boolean}
   */
  identify(userId, properties = {}) {
    const location = this.store.getters['session/location']
    const user = this.store.getters['user/user']
    userId = userId || user.id

    const { locale } = useI18n()

    const extendedProperties = {
      userType: 'Customer',
      language: locale,
      name: user.name,
      phone: user.phone,
      email: user.email,
      birthday: user.birthday,
      address: {
        street: location?.street,
        city: location?.city,
        postalCode: location?.zipcode,
        country: 'Nederland',
      },
      ...properties,
    }

    if (userId) {
      // als segment id niet gelijk is aan user id dan resetten
      this.analyticsBrowser.user().then((user) => {
        // reset if current userId is different from segment's stored user id
        const segmentUserId = user.id()
        if (segmentUserId && userId !== segmentUserId) {
          this.track('User Switched')
          this.analyticsBrowser.reset()
        }

        // identify user
        return this.analyticsBrowser.identify(userId, extendedProperties, {
          context: getContext(),
        })
      })
    } else {
      return this.analyticsBrowser.identify(extendedProperties, {
        context: getContext(),
      })
    }
  }

  /**
   * @param eventName
   * @param properties
   * @param eventExtras
   *
   * @returns {*}
   */
  track(eventName, properties, eventExtras) {
    if (!eventName) return

    return this.analyticsBrowser.track(eventName, properties, {
      context: getContext(),
      ...eventExtras,
    })
  }

  /**
   * @returns {*}
   */
  reset() {
    return this.analyticsBrowser.reset()
  }
}
