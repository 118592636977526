import type { TrackingDriver } from "@/tracking/contracts/TrackingDriver"
import { ApiTrackingDriver } from "@/tracking/drivers/ApiTrackingDriver"
import { useHttp } from "@/composables/useHttp"

let driver: TrackingDriver | null = null

function createTracking(store): TrackingDriver {
  driver = new ApiTrackingDriver(useHttp(), store)

  return driver
}

function useTracking(): TrackingDriver {
  if (null === driver) {
    throw new Error('Tracking driver not initialized')
  }

  return driver
}

export { useTracking, createTracking }