import { useHttp } from "@/composables/useHttp"

export function useBasketHttp() {
  const http = useHttp()

  async function validate(data) {
    const response = await http.post('basket', data)

    return response.data.data;
  }

  async function changeDistributionType(data) {
    const response = await http.post('basket/change-distribution', data)

    return response.data.data;
  }

  async function verifyDiscountCode(establishmentId, code) {
    const response = await http.post('basket/verify-discount-code', {
        establishment_id: establishmentId,
        code
    })

    return response.data.data
  }

  return {
    validate,
    changeDistributionType,
    verifyDiscountCode
  }
}
