import type { TrackingDriver } from "@/tracking/contracts/TrackingDriver"
import type { Axios } from "axios"
import type { Store } from "vuex"
import type { TrackingEvent, TrackingEventPayload } from "@/tracking/types/TrackingEvent.ts"
import { Capacitor } from "@capacitor/core"

export class ApiTrackingDriver implements TrackingDriver {
  http: Axios
  store: Store<any>

  hasStarted: boolean
  queue: { event: string, data: object }[]

  constructor(http: Axios, store: Store<any>) {
    this.http = http
    this.queue = []
    this.store = store
  }

  async start(): Promise<void> {
    try {
      await this.http.post("tracking/session")

      this.hasStarted = true

      this.sendQueue()
    } catch (error) {
      console.debug(error)
    }
  }

  async send<T extends TrackingEvent>(
    event: T,
    payload: TrackingEventPayload[T],
  ): Promise<void> {
    const data = {
      event,
      data: {
        ...(this.store.getters['session/tracking']),
        is_app: __CAPACITOR__ && Capacitor.isNativePlatform(),
        ...payload,
      },
    }

    if (!this.hasStarted) {
      this.queue.push(data)

      return
    }

    try {
      await this.http.post("tracking/event", data)
    } catch (error) {
      console.debug(error)
    }
  }

  private sendQueue(): void {
    this.queue.forEach((data) => {
      try {
        this.http.post("tracking/event", data)
      } catch (error) {
        console.debug(error)
      }
    })
    this.queue = []
  }
}