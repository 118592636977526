import CategoryResource from '@/resources/CategoryResource.js'
import { useHttp } from '@/composables/useHttp'

export const namespaced = true

export const state = () => ({
  restaurantCategories: [],
})

export const getters = {
  restaurantCategories: (state) => state.restaurantCategories.map(category => new CategoryResource(category)),
}

export const actions = {
  async fetchCategories({ commit }) {
    const http = useHttp()

    const { data } = await http.get('sidebar?type=1')

    commit('SET_CATEGORIES', data.categories)
  },
}

export const mutations = {
  SET_CATEGORIES: (state, restaurantCategories) => {
    state.restaurantCategories = restaurantCategories
  },
}
