import UserResource from '@/resources/UserResource'
import { Address } from '@/http/models/Address'

const SET_FETCHING = 'SET_FETCHING'
const RESET_USER = 'RESET_USER'
const SET_USER_COMPONENT = 'SET_USER_COMPONENT'
const SET_USER_ADDRESSES = 'SET_USER_ADDRESSES'
const SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION =
  'SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION'
const SET_GUEST_PHONE = 'SET_GUEST_PHONE'
const SET_GUEST_EMAIL = 'SET_GUEST_EMAIL'
const SET_GUEST_NAME = 'SET_GUEST_NAME'

export const getDefaultState = () => ({
  user: {
    phone: '',
    name: '',
    email: '',
  }, // contains account data only
  guest: {
    email: '',
    name: '',
    phone: '',
  },
  addresses: null, // contains all addresses the user has used in the past
  hasCreatedAccountFromGuestSession: false,
})

export const state = () => ({ ...getDefaultState() })

export const getters = {
  user: (state) => new UserResource(state.user),

  token: (state) => state.token,

  hasCreatedAccountFromGuestSession: (state) =>
    state.hasCreatedAccountFromGuestSession,

  addresses: (state) => state.addresses?.map((address) => new Address(address)) ?? [],
}

export const actions = {
  setUser({ commit }, user) {
    commit(SET_USER_COMPONENT, {
      key: 'phone',
      value: user.phone,
    })

    commit(SET_USER_COMPONENT, {
      key: 'email',
      value: user.email,
    })

    commit(SET_USER_COMPONENT, {
      key: 'name',
      value: user.name,
    })
  },

  setUserComponent: ({ commit }, {
    key,
    value,
  }) =>
    commit(SET_USER_COMPONENT, {
      key,
      value,
    }),

  setUserHasCreatedAccountFromGuestSession: ({ commit }, bool) => {
    commit(SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION, bool)
  },

  applyAccount({ commit }, {
    id,
    phone_number,
    email,
    name,
    date_of_birth,
  }) {
    commit(SET_USER_COMPONENT, {
      key: 'id',
      value: id,
    })

    commit(SET_USER_COMPONENT, {
      key: 'phone',
      value: phone_number ?? '',
    })

    commit(SET_USER_COMPONENT, {
      key: 'email',
      value: email,
    })

    commit(SET_USER_COMPONENT, {
      key: 'name',
      value: name,
    })

    commit(SET_USER_COMPONENT, {
      key: 'birthday',
      value: date_of_birth,
    })
  },

  async storeAddresses({ commit }, addresses) {
    commit(SET_USER_ADDRESSES, addresses)
  },

  storeGuestName({ commit }, name) {
    commit(SET_GUEST_NAME, name)
  },

  storeGuestPhone({ commit }, phone) {
    commit(SET_GUEST_PHONE, phone)
  },

  storeGuestEmail({ commit }, email) {
    commit(SET_GUEST_EMAIL, email)
  },
}

export const mutations = {
  [RESET_USER]: (state) => {
    Object.entries(getDefaultState()).forEach(([key, value]) => {
      state[key] = value
    })
  },

  [SET_USER_COMPONENT]: (state, {
    key,
    value,
  }) => {
    if (!state.user) state.user = {}
    state.user = {
      ...state.user,
      [key]: value,
    } // for reactivity
  },

  [SET_USER_ADDRESSES]: (state, addresses) => {
    state.addresses = addresses
  },

  [SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION]: (state, bool) => {
    state.hasCreatedAccountFromGuestSession = bool
  },

  [SET_GUEST_PHONE]: (state, phone) => (state.guest.phone = phone),

  [SET_GUEST_EMAIL]: (state, email) => (state.guest.email = email),

  [SET_GUEST_NAME]: (state, name) => (state.guest.name = name),
}
