import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import 'dayjs/locale/en'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useI18n } from '@/i18n'
import {
  selectFirstAvailableTimeSlot as determineFirstAvailableTimeSlot,
} from './selectFirstAvailableTimeSlot'

import { useGa } from '@/composables/useGa'
import { getDefaultState } from './state'
import {
  ADD_TO_BASKET_ITEMS,
  CHANGE_AMOUNT_OF_BASKET_ITEM,
  CHANGE_PRODUCTSET_OF_BASKET_ITEM,
  RESET_BASKET_INFO,
  RESET_BASKET_ITEMS,
  RESET_CART,
  SET_BASKET_INFO,
  SET_CART_SYNCING,
  SET_COUPON_CODE,
  SET_CURRENT_VIEW,
  SET_DISTRIBUTION_DATE,
  SET_DISTRIBUTION_TIME,
  SET_DISTRIBUTION_TYPE,
  SET_ESTABLISHMENT,
  SET_ESTABLISHMENT_DISTRIBUTION,
  SET_GIFTCARD_CODE,
  SET_INSTRUCTIONS_OF_BASKET_ITEM,
  SET_LOADED_TIME_SLOTS,
  SET_ORDER_UUID,
  SET_PAYMENT_ISSUER,
  SET_PAYMENT_METHOD,
  SET_RESTAURANT_TIP,
  SET_TIME_SLOTS,
} from './mutations'

import DistributionTypes from '@/config/DistributionTypes'

import { CARTCHANGED_MODAL } from '@/store/modals'
import { BasketItemProductResource } from '@/resources/cart/BasketItemProductResource'
import AttributeTypes from '@/config/AttributeTypes'
import { useBasketHttp } from '@/http/basketHttp'
import { useEstablishmentHttp } from '@/http/establishmentHttp'

dayjs.extend(customParseFormat)

/*
 * Dispatch an action to add an item.
 * The mutation (ADD_TO_BASKET_ITEMS) makes sure
 * to find the product if it already exists
 */
async function add(
  {
    commit,
    dispatch,
    getters,
    rootGetters,
    rootState,
  },
  {
    product,
    productSetId,
    selectedAttributes,
    amount,
    distributionType = null,
  },
) {
  commit(SET_CART_SYNCING, true)

  // Add the item to the basket
  commit(ADD_TO_BASKET_ITEMS, {
    product,
    productSetId,
    selectedAttributes,
    amount,
    basketItems: getters.basketItems,
  })

  // because we are adding a product, we need to set the cart establishment
  // the cart establishment will be the current establishment
  // so later on, we can check if the establishment is the same as the cart establishment
  await dispatch('setEstablishment', rootState.establishment.establishment)

  commit(SET_ORDER_UUID, getDefaultState().orderUuid)

  // Set the distribution type of the cart (it has been set probably, but just do it anyway)
  if (distributionType) commit(SET_DISTRIBUTION_TYPE, distributionType)

  // this.$segment.handle(CART_PRODUCT_ADDED, {
  //   product: product,
  //   establishment: getters.establishment,
  //   tracking: rootGetters['session/tracking'],
  // })

  const ga = useGa({
    gtm: this.$gtm,
    store: this.$store,
    sentry: this.$sentry,
  })

  ga.trackAddProduct({
    product,
    amount,
    selectedAttributes,
    establishment: getters.establishment,
  })

  // Fetch new basket info from the server
  await dispatch('fetchBasketInfo')
  commit(SET_CART_SYNCING, false)
}

/*
 * Add multiple products to the basket.
 * Is used in the reorder modal on the establishment page.
 */
// async function addReorderProducts(
//   { commit, getters, rootGetters, dispatch },
//   { products, establishment }
// ) {
//   commit(SET_CART_SYNCING, true)

//   // Set the establishment using the raw server data
//   dispatch('setEstablishment', establishment.raw || establishment)

//   // Set the cart distribution type to match the session
//   commit(SET_DISTRIBUTION_TYPE, rootGetters['session/distributionType'])

//   // Reset the order id
//   commit(SET_ORDER_UUID, getDefaultState().orderUuid)

//   // TODO: integrate tracking

//   // Add the item to the basket
//   products
//     .filter(({ amount, available }) => available && amount)
//     .forEach((product) => {
//       commit(ADD_TO_BASKET_ITEMS, {
//         product,
//         // TODO: productSetId
//         selectedAttributes: product.prepareEmptyAttributesArray(),
//         amount: product.amount,
//         basketItems: getters.basketItems,
//       })
//     })

//   await dispatch('fetchBasketInfo')

//   commit(SET_CART_SYNCING, false)
// }

async function changeAmount({
  commit,
  dispatch,
  getters,
}, {
  hash,
  amount,
}) {
  commit(SET_CART_SYNCING, true)

  try {
    commit(CHANGE_AMOUNT_OF_BASKET_ITEM, {
      hash,
      amount,
      basketItems: getters.basketItems,
    })

    await dispatch('fetchBasketInfo')
  } finally {
    commit(SET_CART_SYNCING, false)
  }
}

async function setInstructions({
  commit,
  dispatch,
  getters,
}, {
  hash,
  instructions,
}) {
  commit(SET_CART_SYNCING, true)

  try {
    commit(SET_INSTRUCTIONS_OF_BASKET_ITEM, {
      hash,
      instructions,
      basketItems: getters.basketItems,
    })

    await dispatch('fetchBasketInfo')
  } finally {
    commit(SET_CART_SYNCING, false)
  }
}

function emptyCart({ commit }) {
  commit(RESET_CART)
}

/*
 * Fetch all timeslots (available and non-available)
 * for the specific order in this store (it depends on amount of product and
 * distribution type)
 */
async function fetchTimeSlots({
  commit,
  getters,
  dispatch,
  rootGetters,
}) {
  commit(SET_LOADED_TIME_SLOTS, false)

  const establishment = getters.establishment
  const distributionType = getters.distributionType

  const products = getters.basketInfo.products.map((product) => ({
    product_id: product.id,
    product_set_id: product.product_set_id,
    quantity: product.amount,
  }))

  const params = {
    distribution: distributionType,
    products,
  }

  if (distributionType === DistributionTypes.Delivery) {
    const {
      coordinates: {
        lat,
        lng,
      },
    } = rootGetters['session/location']

    params.location = {
      lat,
      lng,
    }
  }

  try {
    const { timeslots } = useEstablishmentHttp()

    const timeSlots = await timeslots(
      establishment.slug,
      establishment.location.country,
      establishment.location.city,
      params,
    )

    commit(SET_TIME_SLOTS, timeSlots)
  } catch (e) {
    commit(SET_TIME_SLOTS, [])
  } finally {
    await dispatch('selectFirstAvailableTimeSlot')

    commit(SET_LOADED_TIME_SLOTS, true)
  }
}

/*
 * Update the cart in this store, based on the cart retrieved from the server
 */
function updateCartByServer({
  commit,
  getters,
}, basketInfo) {
  // Store the info to basketInfo (we'll generate the basket from this)
  commit(SET_BASKET_INFO, basketInfo)

  // Reset our client side basket (local)
  commit(RESET_BASKET_ITEMS)

  basketInfo.products.forEach((item) => {
    const product = new BasketItemProductResource(item)

    const selectedAttributes = new Map()
    item.sub_product_groups.map((subProductGroup) => {
      if (subProductGroup.sub_products.length === 0) {
        return
      }

      if (subProductGroup.type === AttributeTypes.DropDown || subProductGroup.type === AttributeTypes.OptionalDropdown) {
        selectedAttributes.set(
          subProductGroup.id,
          String(subProductGroup.sub_products[0].id),
        )

        return
      }

      // checkbox
      selectedAttributes.set(
        subProductGroup.id,
        subProductGroup.sub_products.reduce((prev, current) => {
          return {
            ...prev,
            [current.id]: true,
          }
        }, {}),
      )
    })

    commit(ADD_TO_BASKET_ITEMS, {
      product,
      productSetId: product.product_set_id,
      selectedAttributes,
      amount: item.amount,
      basketItems: getters.basketItems,
    })
  })
}

/*
 *  Show modals if we need to to inform the user what the server has changed
 */
async function showModalByServer({ dispatch }, modal) {
  // Check if we must show a modal, show the modal if so
  if (!modal) return

  await dispatch(
    'modal/show',
    {
      title: modal.title,
      message: modal.text,
    },
    { root: true },
  )
}

/*
 * Converts basket info from one distribution type to the other
 */
async function updateCartByDistribution({
  dispatch,
  getters,
  rootGetters,
  state,
}, type) {
  if (!getters.hasEstablishment || !getters.hasBasketItems) {
    await dispatch('session/storeDistributionType', type, { root: true })

    return
  }

  const {
    id: establishmentUuid,
    slug: establishmentSlug,
    name: establishmentName,
  } = getters.establishment

  const data = {
    establishment: {
      id: establishmentUuid,
      slug: establishmentSlug,
      name: establishmentName,
    },
    distribution: getters.distributionType,
    payment: {
      service_id: getters.paymentMethod?.service_id,
      method: getters.paymentMethod?.id,
      option: getters.paymentIssuer?.id,
    },
    restaurant_tip: getters.restaurantTip,
    service_fee: 0.35,
  }

  if (state.couponCode !== null) {
    data.coupon = {
      code: state.couponCode,
    }
  }

  if (state.giftCardCode !== null) {
    data.giftcard = {
      code: state.giftCardCode,
    }
  }

  if (rootGetters['session/hasLocation']) {
    data.location = {
      lat: rootGetters['session/location'].coordinates.lat,
      lng: rootGetters['session/location'].coordinates.lng,
    }
  }

  data.products = getters.basketItems.items.map((productResource) => ({
    hash: productResource.product.hash,
    id: productResource.product.id,
    product_set_id: productResource.product.product_set_id,
    amount: productResource.amount,
    title: productResource.product.title,
    subtitle: productResource.product.subtitle,
    unit_price: productResource.product.price,
    sub_product_groups: productResource.product.sub_product_groups,
  }))

  data.new_distribution = Number(type)

  let error = false
  const { changeDistributionType } = useBasketHttp()

  const response = await changeDistributionType(data).catch((e) => {
    if (e.response.status === 400) {
      error = true
      // dispatch(
      //   'modals/toggle',
      //   { modal: ERROR_MODAL, open: true, data: {error: e.response}, },
      //   { root: true, }
      // )
      dispatch('emptyCart')
    }
    if (e.response.status === 422) {
      error = true
      // dispatch(
      //   'modals/toggle',
      //   { modal: ERROR_MODAL, open: true, data: {error: e.response}, },
      //   { root: true, }
      // )
      dispatch('emptyCart')
    }
  })

  if (error) return

  if (response.removed.length > 0) {
    await dispatch(
      'modals/toggle',
      {
        modal: CARTCHANGED_MODAL,
        open: true,
        data: {
          ...response,
          distributionType: type,
        },
      },
      { root: true },
    )

    return
  }

  await dispatch('applyCartChanges', {
    ...response,
    new_distribution: Number(type),
  })
  // on the establishment page, update the session AND the cart distribution type
  // on the checkout page, update the session AND the cart distribution type
  await dispatch('session/storeDistributionType', type, { root: true })
  await dispatch('storeDistributionType', type)
  await dispatch('fetchBasketInfo', null)
}

async function applyCartChanges({
  getters,
  rootGetters,
  commit,
  dispatch,
}, changes) {
  const i18n = useI18n()
  const t = i18n.global.t

  changes.removed.forEach(async (remove) => {
    commit(CHANGE_AMOUNT_OF_BASKET_ITEM, {
      hash: remove.hash,
      amount: 0,
      basketItems: getters.basketItems,
    })
  })

  changes.changes.forEach(async (change) => {
    commit(CHANGE_PRODUCTSET_OF_BASKET_ITEM, {
      hash: change.old.hash,
      productSetId: change.new.product_set_id,
      basketItems: getters.basketItems,
    })
  })

  // Check if overlapping products with time limitations result in 0 timeslots
  if (changes.changes.length > 0) {
    let basketItems = rootGetters['cart/basketItems'].items.map((item) => {
      return {
        product_id: item.product.id,
        product_set_id: item.product.product_set_id,
        quantity: item.amount,
      }
    })

    const params = {
      distribution: changes.new_distribution,
      products: basketItems,
    }

    if (params.distribution === DistributionTypes.Delivery) {
      params.location = rootGetters['session/location'].coordinates
    }

    const establishment = getters.establishment

    const { timeslots } = useEstablishmentHttp()

    const timeSlots = await timeslots(
      establishment.slug,
      establishment.location.country,
      establishment.location.city,
      params,
    )

    const slotsAvailableTotal = timeSlots.reduce((acc, curr) => acc + curr.slots_available_count, 0)

    if (slotsAvailableTotal === 0) {
      return dispatch('modal/show', {
        closable: true,
        dataTestId: 'empty-cart-confirmation-modal',
        title: t('confirmation-modal.notimeslots-distribution.title', { new_type: changes.new_distribution === DistributionTypes.Delivery ? t('terms.delivery') : t('distribution-toggle.takeaway') }),
        message: t('confirmation-modal.notimeslots-distribution.message', {
          new_type: changes.new_distribution === DistributionTypes.Delivery ? t('terms.delivery')
            .toLowerCase() : t('distribution-toggle.takeaway').toLowerCase(),
          old_type: getters.distributionType === DistributionTypes.Delivery ? t('terms.delivery')
            .toLowerCase() : t('distribution-toggle.takeaway').toLowerCase(),
        }),
        callback: [
          {
            label: t('confirmation-modal.notimeslots-distribution.cancel-btn'),
            dataTestId: 'notimeslots-distribution-confirmation-modal-cancel',
            action: () => {
              //fetch timeslots
              dispatch('cart/fetchTimeSlots', null, { root: true })
            },
            properties: {
              type: 'secondary',
            },
          },
          {
            label: t('confirmation-modal.notimeslots-distribution.confirm-btn', {
              old_type: getters.distributionType === DistributionTypes.Delivery ? t('terms.delivery')
                .toLowerCase() : t('distribution-toggle.takeaway').toLowerCase(),
            }),
            dataTestId: 'notimeslots-distribution-confirmation-modal-confirm',
            action: () => {
              dispatch('cart/emptyCart', null, { root: true })
            },
          },
        ],
      }, { root: true })
    }
  }
}

/*
 * Fetch basket info from server based on cart in this store
 */
async function fetchBasketInfo({
  commit,
  state,
  rootGetters,
  getters,
  dispatch,
}) {
  if (!getters.hasEstablishment || !getters.hasBasketItems) {
    // If we don't have an establishment in the cart
    // or don't have items, don't fetch data,
    // but assume we may need to reset the basket info
    commit(RESET_BASKET_INFO)

    return
  }

  const {
    id: establishmentUuid,
    slug: establishmentSlug,
    name: establishmentName,
  } = getters.establishment

  const data = {
    establishment: {
      id: establishmentUuid,
      slug: establishmentSlug,
      name: establishmentName,
    },
    distribution: getters.distributionType,
    payment: {
      service_id: getters.paymentMethod?.service_id,
      method: getters.paymentMethod?.id,
      option: getters.paymentIssuer?.id,
    },
    restaurant_tip: getters.restaurantTip,
    service_fee: 0.35,
  }

  if (state.couponCode !== null) {
    data.coupon = {
      code: state.couponCode,
    }
  }

  if (state.giftCardCode !== null) {
    data.giftcard = {
      code: state.giftCardCode,
    }
  }

  if (getters.distributionType === DistributionTypes.Delivery) {
    data.location = {
      lat: rootGetters['session/location'].coordinates.lat,
      lng: rootGetters['session/location'].coordinates.lng,
    }
  }

  data.products = getters.basketItems.items.map((productResource) => ({
    hash: productResource.product.hash,
    id: productResource.product.id,
    product_set_id: productResource.product.product_set_id,
    amount: productResource.amount,
    title: productResource.product.title,
    subtitle: productResource.product.subtitle,
    unit_price: productResource.product.price,
    sub_product_groups: productResource.product.sub_product_groups,
    instructions: productResource.product.instructions,
  }))

  // validate the basket using the API
  try {
    const { validate } = useBasketHttp()

    const basketInfo = await validate(data)

    await Promise.all([
      dispatch('updateCartByServer', basketInfo),
      dispatch('showModalByServer', basketInfo.modal),
    ])
  } catch (error) {
    if (error.response.data.data?.code === 'UNKNOWN_PRODUCTS') {
      await dispatch('emptyCart')

      return
    }

    if (error.response.data.data?.code === 'INVALID_TIMESLOT') {
      const { message } = error.response.data.data

      // Show the message from the server (timeslot not available anymore)
      // and in the callback, make sure we reload timeslots and show the checkout details modal
      await store.dispatch('modal/show', {
        dataTestId: 'invalid-timeslot-modal',
        message: message,
      })

      return
    }

    return Promise.reject(error)
  }
}

export default {
  add,
  // addReorderProducts,
  changeAmount,
  emptyCart,
  setInstructions,
  async selectFirstAvailableTimeSlot({
    getters,
    dispatch,
    state,
  }) {
    const dateSlots = getters.timeslots

    const {
      date,
      time,
    } = determineFirstAvailableTimeSlot(dateSlots, state.distributionDate, state.distributionTime)

    await dispatch('storeDistributionDate', date)
    await dispatch('storeDistributionTime', time)
  },
  fetchTimeSlots,
  updateCartByServer,
  showModalByServer,
  updateCartByDistribution,
  applyCartChanges,
  fetchBasketInfo,

  storeVoucherCode({
    commit,
    dispatch,
  }, {
    code,
    type,
    fetchBasketInfo = true,
  }) {
    if (type === 'coupon') {
      commit(SET_COUPON_CODE, code)
    } else if (type === 'giftcard' || type === 'voucher') {
      commit(SET_GIFTCARD_CODE, code)
    }

    if (fetchBasketInfo) {
      dispatch('fetchBasketInfo')
    }
  },

  removeVoucherCode({
    commit,
    dispatch,
  }, { type }) {
    if (type === 'coupon') {
      commit(SET_COUPON_CODE, null)
    } else if (type === 'giftcard') {
      commit(SET_GIFTCARD_CODE, null)
    } else if (type === 'voucher') {
      commit(SET_GIFTCARD_CODE, null)
    }

    dispatch('fetchBasketInfo')
  },

  async storeRestaurantTip({
    commit,
    dispatch,
  }, {
    tip,
    fetchBasketInfo = true,
  }) {
    commit(SET_CART_SYNCING, true)

    commit(SET_RESTAURANT_TIP, tip)

    if (fetchBasketInfo) {
      await dispatch('fetchBasketInfo')
    }

    commit(SET_CART_SYNCING, false)
  },

  storeDistributionType({ commit }, distributionType) {
    commit(SET_DISTRIBUTION_TYPE, distributionType)
  },

  storeDistributionDate({ commit }, distributionDate) {
    commit(SET_DISTRIBUTION_DATE, distributionDate)
  },

  storeDistributionTime({ commit }, distributionTime) {
    commit(SET_DISTRIBUTION_TIME, distributionTime)
  },

  storePaymentMethod({ commit }, paymentMethod) {
    commit(SET_PAYMENT_METHOD, paymentMethod)
  },

  storePaymentIssuer({ commit }, paymentIssuer) {
    commit(SET_PAYMENT_ISSUER, paymentIssuer)
  },

  storeOrderUuid({ commit }, uuid) {
    commit(SET_ORDER_UUID, uuid)
  },

  setEstablishment({
    getters,
    commit,
    dispatch,
  }, establishment) {
    // if we change establishments, we assume the instructions should be reset
    if (getters.establishment?.id !== establishment.id) {
      dispatch('session/storeInstructions', null, { root: true })
    }

    commit(SET_ESTABLISHMENT, establishment)
  },

  storeEstablishmentDistribution({ commit }, establishmentDistribution) {
    commit(SET_ESTABLISHMENT_DISTRIBUTION, establishmentDistribution)
  },

  setCurrentView({ commit }, view) {
    commit(SET_CURRENT_VIEW, view)
  },
}
