export const namespaced = true

export const state = () => ({
  cities: [],
})

export const getters = {
  cities: (state) => state.cities,
}

export const actions = {}

export const mutations = {
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  }
}
