import { useStore } from '@/stores'
import { useSSRContext } from 'vue'
import { useSegment } from './useSegment'
import { EXPERIMENT_STARTED } from '@/plugins/globals/segment/handlers'
import { isBefore, parse } from 'date-fns'
import { useHttp } from '@/composables/useHttp'

export function useExperiments() {
  const store = useStore()
  const http = useHttp()
  const segment = useSegment()

  function chooseRandomVariant(variations) {
    const {
      variant: { slug },
    } = variations.reduce((picked, variant) => {
      const chance = variant.probability * Math.random()

      // return this variant in the first round for a nice start
      if (!picked) return { variant, chance }

      // pick either the current or old variant based on the chance
      return chance > picked.chance ? { variant, chance } : picked
    }, null)

    return slug
  }

  async function startExperiments() {
    const { request, response } = useSSRContext()

    try {
      const { data } = await http.get('experiments')

      for (const experiment of data.data) {
        if (experiment.attributes.active) {
          await getVariant(experiment.attributes.slug, {request, response})
        }
      }
    } catch (e) {
    }
  }

  async function getVariant(name, {request, response} = {}) {

    let val = request.cookies[name] ?? null

    try {
      const { data } = await http.get(`experiments/${name}`)

      const expireDate = parse(
        data.data.attributes.active_until,
        'yyyy-MM-dd HH:mm:ss',
        new Date()
      )

      if (val === null) {
        // if the expire date is in the past, we don't need to do anything
        if (isBefore(expireDate, new Date())) return

        val = chooseRandomVariant(data.data.attributes.variations)
      }

      await store.dispatch('experiments/addExperiment', {
        name,
        value: val,
        date: expireDate,
      })

      response.cookie(name, val, { expires: expireDate })

      segment.handle(EXPERIMENT_STARTED, {
        name,
        variant: val,
        tracking: store.getters['session/tracking']
      })
    } catch (e) {
      // this should not happen, because we are looking for a non existing experiment
    }
  }

  return {
    getVariant,
    startExperiments,
  }
}
