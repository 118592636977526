export const auth = {
  state: () => ({
    token: null,
  }),
  actions: {
    setToken: ({commit}, token: string) => {
      commit('SET_TOKEN', token);
    },
    clear: ({commit}) => {
      commit('SET_TOKEN', null);
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    }
  },
  getters: {
    getToken: (state) => state.token,
    loggedIn: (state) => state.token !== null
  }
}
