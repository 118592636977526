import type * as Sentry from '@sentry/vue'
import type {App} from 'vue'
import type {Router} from 'vue-router'
import taptic from './directives/taptic'
import bodyScrollLock from './directives/body_scroll_lock'
import draggable from './directives/draggable'
import {createSegment, provideSegment} from '@/composables/useSegment'
import {filters} from './globals/filters'
import {useStore} from "@/stores";

type PluginOptions = {
  router: Router
  sentry: typeof Sentry | undefined
}

export function plugins(options: PluginOptions) {
  return {
    async install(app: App) {
      const store = useStore()

      app.directive('taptic', taptic)
      app.directive('body-scroll-lock', bodyScrollLock)
      app.directive('draggable', draggable)

      filters.forEach(([key, value]) => {
        app.config.globalProperties[key] = value
        app.provide(key, value)
      })

      // Vuex properties

      if (!import.meta.env.SSR) {
        // Fixes for Facebook native app browser
        window._AutofillCallbackHandler = window._AutofillCallbackHandler
          || function () {
          }
        window.instantSearchSDKJSBridgeClearHighlight = window.instantSearchSDKJSBridgeClearHighlight
          || function () {
          }
      }

      const segment = await createSegment({store, router: options.router, sentry: options.sentry})

      app.config.globalProperties.$segment = segment
      provideSegment(app, segment)
    },
  }
}
